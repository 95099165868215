<template>
  <div>
    <v-simple-table class="process-template-table" fixed-header max-height="300">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-if="hasSelectSlot"></th>
            <th>
              <span>ID/Название</span>
              <input v-model="filter.name" class="pt-input"></input>
            </th>
            <th>
              <span>Группа/объект</span>
              <input v-model="filter.groupName" class="pt-input"></input>
            </th>
            <th>
              <span>Создан</span>
              <v-dialog
                v-model="datesDialog"
                persistent
                width="600px"
                >
                <template v-slot:activator="{ on }">
                  <input :value="formatDates" class="pt-input" v-on="on"></input>
                </template>
                <div class="d-flex justify-space-around white pa-1">
                  <v-date-picker v-model="dates.from" no-title scrollable></v-date-picker>
                  <v-date-picker v-model="dates.to" no-title scrollable></v-date-picker>
                </div>
                <div class="d-flex pa-3 white">
                  <v-btn color="default" class="mr-3" @click="datesDialog = false">Отмена</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="info" @click="clearDates">Очистить</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="changeDates">Далее</v-btn>
                </div>
              </v-dialog>
            </th>
            <th v-if="hasActionSlot"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in processes" :key="p.id">
            <td v-if="hasSelectSlot"><slot name="select" :process="p"></slot></td>
            <td v-if="hasNameSlot"><slot name="name" :process="p"></slot></td>
            <td v-if="!hasNameSlot">{{ pt.name }}</td>
            <td>{{ p.template.groupName }}</td>
            <td>{{ secToDatetime(p.createdAt) }}</td>
            <td v-if="hasActionSlot"><slot name="action" :process="p"></slot></td>
          </tr>
          <tr v-if="processes.length === 0">
            <td colspan="5">Не найдено ни одного тех. процесса</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination v-if="pagesTotal > 1" class="mt-3" v-model="page" :length="pagesTotal"></v-pagination>
  </div>
</template>

<script>
import { secToDate, dateToSec, secToDatetime } from '@/libs/time.js'
export default {
  data: () => ({
    secToDatetime,
    processes: [],
    filter: {
      name: '',
      groupName: '',
      dates: {
        from: '',
        to: ''
      },
    },
    datesDialog: false,
    dates: {
      from: '',
      to: ''
    },
    page: 1,
    pagesTotal: 0,
    apiTimeout: null,
    loading: false
  }),
  props: {
    perPage: {
      type: Number,
      default: 5,
    },
    queryParams: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      default: 'manufacture/process/index'
    }
  },
  computed: {
    hasSelectSlot() {
      return !!this.$scopedSlots['select']
    },
    hasActionSlot() {
      return !!this.$scopedSlots['action']
    },
    hasNameSlot() {
      return !!this.$scopedSlots['name']
    },
    formatDates() {
      if(this.filter.dates.from) {
        if(this.filter.dates.to) {
          return secToDate(this.filter.dates.from) + ' - ' + secToDate(this.filter.dates.to)
        }
        return secToDate(this.filter.dates.from)
      }
      return null
    }
  },
  methods: {
    getProcesses() {
      this.loading = true
      this.$axios.get(this.url, {
        params: {
          name: this.filter.name,
          group: this.filter.group,
          dateFrom: this.filter.dates.from,
          dateTo: this.filter.dates.to,
          ...this.queryParams,
          page: this.page,
          pageSize: this.perPage,
        }
      }).then(r => {
        this.processes = r.data.items.slice()
        this.pagesTotal = r.data.pagination.pagesTotal
        this.$emit('changed')
      }).catch(() => {}).finally(() => { this.loading = false })
    },
    changeDates() {
      this.filter.dates.from = dateToSec(this.dates.from)
      this.filter.dates.to = dateToSec(this.dates.to)
      this.datesDialog = false
    },
    clearDates() {
      this.dates.from = ''
      this.dates.to = ''
      this.filter.dates.from = ''
      this.filter.dates.to = ''
      this.datesDialog = false
    }
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.apiTimeout)
        this.apiTimeout = setTimeout(() => this.getProcesses(), 500)
      },
      deep: true
    },
    page(newPage) {
      this.getProcesses()
    },
  },
  mounted() {
    this.getProcesses()
  }
}
</script>

<style>
  .process-template-table {
    overflow-y: auto !important;
  }
  .process-template-table th {
    text-align: center !important;
    vertical-align: bottom !important;
    padding: 10px;
  }
  .process-template-table th span {
    display: block;
  }
  .process-template-table td {
    text-align: center !important;
  }
  .pt-input {
    background: transparent;
    align-items: stretch;
    border: 1px solid;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
    text-align: center;
  }
  .pt-input:before {
    border-color: rgba(0, 0, 0, 0.42);
  }
  .pt-input:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
  .pt-input:focus {
    border-color: #1976d2;
    outline: none;
  }
</style>
